<!--企业  -->
<template>
  <div class="">
    <ax-table
      ref="roleTable"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      :columns="columns"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :clickable="false"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @add="add"
      @searchlist="searchlist"
      @reset="reset"
      @export="expor"
      @exportAll="exportAll"
      @pop-confirm="popConfirm"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @bulkDeletion="deleteSecurityPersonnel"
      :dynamicSlotNames="['gridAddress', 'gridAddress']"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!--  :showToolBar="true"  表格列设置 -->
      <div slot="isDraw" slot-scope="{ record }">
        <div class="plotting" v-if="record.latitude !== ''">
          <div style="background-color: #f6ffed; color: #67c23a">已定位</div>
        </div>
        <div class="plotting" v-else>
          <div style="background-color: #e6e9ee">未定位</div>
        </div>
      </div>
      <div slot="gridAddress" style="display: flex">
        <a-cascader
          :options="options"
          change-on-select
          placeholder="请选择所属网格"
          @change="onChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          :model="cuurentAreaCode"
          v-model="cuurentAreaCode1"
        />
      </div>
    </ax-table>

    <!-- 新增跳转弹窗 -->
    <el-dialog
      title="新增企业"
      :visible.sync="dialogAdd"
      width="50%"
      :z-index="12"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <addPage @closeDialog="dialogAdd = false" @refreshTable="refreshTable"
    /></el-dialog>
    <!-- 编辑跳转弹窗 -->

    <addedit ref="edit" @refreshTable="refreshTable"></addedit>

    <!-- 详情跳转弹窗 -->

    <addetail ref="detail" @refreshTable="refreshTable"></addetail>
  </div>
</template>
    <script>
import api from "./api";
import axTable from "@/components/ax-table";
import addPage from "./addPage.vue";
import addedit from "./addedit.vue";
import addetail from "./addetail.vue";
const type = [
  { label: "农", value: "农" },
  { label: "林", value: "林" },
  { label: "牧", value: "牧" },
  { label: "渔业", value: "渔业" },
  { label: "采矿业", value: "采矿业" },
  { label: "制造业", value: "制造业" },
  { label: "建筑业", value: "建筑业" },
  { label: "交通运输", value: "交通运输" },
  { label: "批发和零售业", value: "批发和零售业" },
  { label: "住宿和餐饮业", value: "住宿和餐饮业" },
  { label: "金融业", value: "金融业" },
  { label: "房地产业", value: "房地产业" },
  { label: "教育", value: "教育" },
  { label: "卫生和社会工作", value: "卫生和社会工作" },
  { label: "文化", value: "文化" },
  { label: "体育和娱乐业", value: "体育和娱乐业" },
  { label: "其他", value: "其他" },
];
const safetyDesc = [
  { label: "无", value: "无" },
  { label: "居民投诉", value: "居民投诉" },
  { label: "噪音", value: "噪音" },
  { label: "污染", value: "污染" },
  { label: "其他", value: "其他" },
];
const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "name",
    options: { placeholder: "企业名称/负责人" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "inputSearch",
    model: "gridAddress",
    options: { placeholder: "所属网格" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属行业",
    type: "select",
    model: "type",
    options: { options: type, placeholder: "所属行业" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "社区影响",
    type: "select",
    model: "safetyDesc",
    options: { options: safetyDesc, placeholder: "社区影响" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addPage, addedit, addetail, axTable },
  data() {
    //这里存放数据
    return {
      api,
      gridAddress: "",
      options: [],
      cuurentAreaCode: "",
      cuurentAreaCode1: [],
      dialogAdd: false,
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 120,
      }),
      searchActions: [
        { name: "searchlist", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [
        { name: "add", text: "新增" },
        { name: "export", text: "导出筛选", type: "#67C23A" },
        { name: "exportAll", text: "导出全部", type: "#67C23A" },
        { name: "bulkDeletion", text: "批量删除", type: "#FA5959" },
      ],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "企业名称",
            dataIndex: "name",
            ellipsis: true,
            width: 80,
            //align: "left", // 设置内容左对齐
          },
          {
            title: "所属网格",
            dataIndex: "gridAddress",
            ellipsis: true,
            width: 150,
          },
          {
            title: "所属行业",
            dataIndex: "type",
            ellipsis: true,
            width: 80,
            //align: "left", // 设置内容左对齐
          },
          {
            title: "负责人",
            dataIndex: "managerName",
            ellipsis: true,
            width: 80,
            //align: "left", // 设置内容左对齐
          },
          {
            title: "联系方式",
            dataIndex: "contact",
            ellipsis: true,
            width: 120,
            //align: "left", // 设置内容左对齐
          },
          {
            title: "位置信息",
            dataIndex: "address",
            ellipsis: true,
            width: 250,
            //align: "left", // 设置内容左对齐
          },
          {
            title: "周边交通类型",
            dataIndex: "transport",
            ellipsis: true,
            width: 120,
            //align: "left", // 设置内容左对齐
          },
          {
            title: "社区影响",
            dataIndex: "safetyDesc",
            ellipsis: true,
            width: 80,
            //align: "left", // 设置内容左对齐
          },
          {
            title: "定位状态",
            dataIndex: "isDraw",
            ellipsis: false,
            width: 80,
            align: "center", // 设置内容左对齐
          },
        ],
        true,
        {
          width: 250,
          actions: [
            {
              props: (record) => {
                return {
                  text: "场所图谱",
                  type: "#556bff",
                  name: "map",
                  link: true,
                  popDisabled: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "编辑",
                  type: "#556bff",
                  name: "edit",
                  link: true,
                  popDisabled: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  type: "#556bff",
                  name: "detail",
                  link: true,
                  popDisabled: true,
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  link: true,
                  type: "#FA5959",
                  popDisabled: false, //delete是false是禁用
                };
              },
            },
          ],
        }
      ),
      dataSourceParams: { kind: 2 },
      visible: false,
      // 多选
      selectedRowKeys: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  mounted() {
    this.owningGrid();
  },
  //方法集合
  methods: {
    // // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
          console.log(options, "3333333333");
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      console.log(value);
      const address = e.map((item) => item.label).join("/");
      this.gridAddress = address;
      this.cuurentAreaCode = value[value.length - 1];
      this.cuurentAreaCode1 = value;
    },
    // 新建项目弹窗
    add() {
      this.dialogAdd = true;
    },

    // 导出全部
    exportAll() {
      const values = JSON.parse(JSON.stringify({ kind: 2 }));
      console.log(this.searchForm.formValues, "89");
      api.export({
        fileName: "企业信息.xls",
        params: values,
      });
    },
    // 导出筛选
    // 导出筛选
    expor() {
      this.searchForm.formValues.gridAddress = this.gridAddress || undefined;
      this.searchForm.formValues.kind = 2;
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues));
      console.log("导出筛选 - 参数: ", values);
      console.log(this.searchForm.formValues, "365");
      api.export({
        fileName: "幼儿园信息.xls",
        params: values,
      });
    },
    //重置
    reset(e) {
      this.cuurentAreaCode = "";
      this.cuurentAreaCode1 = [];
      if (this.$refs.cascader) {
        this.$refs.cascader.$refs.input.value = "";
      }
      this.gridAddress = ""; // 清空 gridAddress 参数
    },

    // 在搜索方法中根据情况传入或不传入 gridAddress 参数
    searchlist(e) {
      const formValues = { ...e.formValues };
      e.formValues.gridAddress = this.gridAddress || undefined;
      // this.refreshTable(formValues);
      this.$refs.roleTable.getDataSource(e.formValues);
    },
    //操作区域
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //场所图谱
        case "map":
          this.$store.commit("item/getMapmsg", record);
          this.$router.push({
            path: "/communityPages/SiteManagement/PlaceMap?title=" + "map",
          });
          break;
        //编辑楼栋
        case "edit":
          this.$refs.edit.openModal(record);
          break;
        //详情
        case "detail":
          this.$refs.detail.openModal(record);
          break;
        //删除
        case "delete":
          break;
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        let that = this;
        // 批量删除添加二次确认
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async function () {
            const res = await api.ids(that.selectedRowKeys.join(","));
            if (res.status === 200) {
              that.$message.success("删除成功");
              // 刷新列表数据
              that.$refs.roleTable.getDataSource();
            } else {
              that.$message.error("删除失败");
            }
          },
        });
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.deleteById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.roleTable.getDataSource();
    },
    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
    <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>